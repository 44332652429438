<template>
  <LayoutDetail>
    <ModalDevicePicker ref="device_picker" @select="(e) => store_chart.addDevice(e)" />
    <RouterLink class="return" to="/dashboard/charts">
      <ArrowLeft :size="18" />
      <span>Mes graphiques</span>
    </RouterLink>
    <h1>Construire un graphique</h1>
    <InputCommon
      v-if="store_chart.getDevices.length > 0"
      class="chart-name"
      id="chart-name"
      gray
      label="Nom du graphique"
      v-model="store_chart.name"
    />
    <h3 v-if="store_chart.getIdString">{{ getChartName() }}</h3>
    <div class="tabs">
      <div
        class="tab-label"
        v-for="(device, index) in store_chart.getDevices"
        :class="{ active: store_chart.selected_device === device.deviceId }"
        v-bind:key="index"
        @click="store_chart.setDevice(device)"
      >
        {{ device.name }}
        <div class="delete-tab">
          <CircleX color="var(--vc-orange)" @click="store_chart.removeDevice(device)" />
        </div>
      </div>
      <div class="new-device tab-label" @click="device_picker?.open">Ajouter un équipement</div>
    </div>
    <ChartDevice v-if="store_chart.devices.length > 0" />
  </LayoutDetail>
</template>

<script lang="ts" setup>
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import { ArrowLeft, CircleX } from 'lucide-vue-next'
import { ref } from 'vue'
import { useChartStore } from '@/stores/store_chart'
import ModalDevicePicker from '@/components/composite/modal/ModalDevicePicker.vue'
import ChartDevice from '@/components/composite/charts/ChartDevice.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'

const store_chart = useChartStore()
const selected_tab = ref(0)
const device_picker = ref<InstanceType<typeof ModalDevicePicker>>()
const chart_name = ref<string>('')

function getChartName() {
  if (store_chart.name) {
    return store_chart.name
  }

  return `Graphique N°${store_chart.getIdString}`
}
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: var(--spacer);
}

.btn {
  display: inline-block;
  margin-left: var(--spacer);
}

.tabs {
  display: flex;
  gap: var(--spacer-0_5);
  margin-bottom: var(--spacer);

  .tab-label {
    padding: 10px;
    cursor: pointer;
    border: 1px solid var(--vc-gray2);
    border-radius: var(--br-small);
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacer-0_5);

    &.active {
      background-color: var(--vc-secondary);
      color: var(--vc-white);
    }

    &:hover:not(.active) {
      background-color: var(--vc-gray1);
      color: var(--vc-text);
    }

    .delete-tab {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      width: 24px;
    }
  }
}

.mobile {
  .tabs {
    flex-direction: column;
  }
}

.return {
  display: flex;
  align-items: center;
  gap: 5px;

  & > svg {
    transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover > svg {
    margin-right: 10px;
  }
}

.return .chart-name {
  margin-bottom: var(--spacer);
  max-width: 400px;
}
</style>
