<template>
  <LayoutCard class="composite-card-data" background="var(--vc-primary)">
    <div class="composite-card-data-header">
      <h3>Données de l'ensemble des bâtiments</h3>
    </div>
    <VueDatePicker
      class="composite-card-savings-datepicker"
      range
      v-model="range"
      placeholder="Selectionnez une date..."
      month-picker
      auto-apply
      locale="fr"
      :format="format"
      @update:model-value="fetch_range"
      hide-input-icon
    />
    <LayoutColumns :columns="4" gap="5px">
      <ChartHomeSavings name="Économie Totale" unit="€" :dataset="calculation_results[0]" />
      <ChartHomeSavings name="Eau Économisée" unit="m³" :dataset="calculation_results[1]" />
      <ChartHomeSavings name="Énergie Économisée" unit="kwh" :dataset="calculation_results[2]" />
      <ChartHomeSavings name="CO₂ évité" unit="kgCO₂" :dataset="calculation_results[3]" />
    </LayoutColumns>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '@/components/layout/LayoutCard.vue'
import { onMounted, ref } from 'vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { type DeviceValue, calculate_total_month } from '@/service/service_calculations'
import ChartHomeSavings from './charts/ChartHomeSavings.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import LayoutColumns from '../layout/LayoutColumns.vue'

const store = useHypervisionStore()
const calculation_results = ref<DeviceValue[][]>([[], [], [], []])
const range = ref<{ year: number; month: number }[]>([])
const fetching = ref<boolean>()

onMounted(() => {
  fetch_range()
})

function format(date: Date[]) {
  const start = date[0].toLocaleDateString('fr', { day: undefined, month: 'long', year: 'numeric' })
  if (date[1]) {
    const end = date[1].toLocaleDateString('fr', { day: undefined, month: 'long', year: 'numeric' })
    return `Période : ${capitalizeFirstLetter(start)} - ${capitalizeFirstLetter(end)}`
  }

  return `Période : ${capitalizeFirstLetter(start)}`
}

function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1)
}

function formatNumberWithSpaces(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

async function fetch_range() {
  if (range.value && range.value.length !== 0) {
    calculation_results.value = [[], [], [], []]
    fetching.value = true

    const date_start = new Date(range.value[0].year, range.value[0].month)
    const date_end = new Date(range.value[1].year, range.value[1].month)
    if (
      date_start.getFullYear() == date_end.getFullYear() &&
      date_start.getMonth() == date_end.getMonth()
    ) {
      date_end.setMonth(date_end.getMonth() + 1)
      console.log(date_end)
    }

    const eur = await store.getTotalRange(date_start, date_end)
    const total_eur = calculate_total_month(eur)
    const total_eau = calculate_total_month(eur, 'eau')
    const total_nrj = calculate_total_month(eur, 'nrj')
    const total_co2 = calculate_total_month(eur, 'co2')

    calculation_results.value = [total_eur, total_eau, total_nrj, total_co2]

    fetching.value = false
  } else {
    if (!range.value || range.value.length == 0) {
      calculation_results.value = store.getHomeDatasets
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.composite-card-data {
  @include vlayout(20px);
  &-header {
    text-align: center;
    color: var(--vc-white);

    &.text-center {
      justify-content: center;
    }

    &-icon {
      width: 36px;
      height: 36px;
      background-color: var(--vc-secondary);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--vc-white);

      img {
        width: 24px;
      }
    }
  }

  .charts-container {
    display: flex;
    gap: var(--spacer-0_5);
  }
}

.mobile,
.tablet {
  .composite-card-data {
    .charts-container {
      flex-direction: column;
    }
  }
}
</style>

<style>
.composite-card-savings-datepicker .dp__input_wrap > input {
  height: 60px;
  border: 1px;
  padding: 20px;
  border-radius: 60px;
  font-weight: 800;
  background-color: #ffffff1a;
  border: 1px solid #ffffff4d;
  color: white;
}
</style>
