<template>
  <div class="chart-savings">
    <VueDatePicker
      class="chart-savings-datepicker"
      dark
      month-picker
      v-model="date"
      locale="fr"
      auto-apply
      :format="format"
      @update:model-value="fetchMonth"
      :clearable="false"
    />
    <CommonInlineData
      style="margin-bottom: var(--spacer); min-width: 300px"
      :label="`Économie du mois ${getMonth()}`"
      :data="`${sum()} €`"
    />
    <div style="flex-grow: 1" v-if="!fetching">
      <div style="height: 140px">
        <Doughnut
          :data="{
            labels: dataset.map((e) => e.label ?? ''),
            datasets: [
              {
                backgroundColor: ['#8EB7FF', '#FEC62E', '#FC6944', '#9C5DF4'],
                data: dataset.map((e) => Math.round(e.value?.value ?? 0))
              }
            ]
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            cutout: 30,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                position: 'nearest',
                callbacks: {
                  label: (e: any) => `${e.formattedValue} €`
                }
              }
            },
            //@ts-ignore
            borderWidth: 0
          }"
        />
      </div>
    </div>
    <CommonLoader class="chart-savings-loader" v-else />
  </div>
</template>

<script setup lang="ts">
import CommonInlineData from '@/components/common/CommonInlineData.vue'
import { calculate_total_month, type DeviceValue } from '@/service/service_calculations'
import type { ApplicationTreeNode } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, Legend } from 'chart.js'
import { onMounted, ref } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { Doughnut } from 'vue-chartjs'
import CommonLoader from '@/components/common/CommonLoader.vue'

const store = useHypervisionStore()
const dataset = ref<{ label: string; value?: DeviceValue }[]>([])
const current_day = new Date()
const fetching = ref(false)
const date = ref({ month: current_day.getMonth(), year: current_day.getFullYear() })
const props = defineProps<{
  node: ApplicationTreeNode
}>()

const format = (date: Date) => {
  return date.toLocaleDateString('fr', { day: undefined, month: 'long', year: 'numeric' })
}

function sum() {
  let output = 0
  dataset.value.map((e) => {
    output += e.value?.value ?? 0
  })

  return Number(Math.round(output).toString())
}

function getMonth() {
  let appostroph = false
  if ([4, 7, 9].includes(date.value.month)) {
    appostroph = true
  }

  const parsed_date = new Date(date.value.year, date.value.month)
  const month_name = parsed_date.toLocaleDateString('fr', {
    day: undefined,
    month: 'long',
    year: undefined
  })
  let result = month_name.charAt(0).toUpperCase() + month_name.slice(1)
  return `${appostroph ? "d'" : 'de '}${result}`
}

async function fetchMonth() {
  fetching.value = true
  const parsed_date = new Date(date.value.year, date.value.month),
    y = parsed_date.getFullYear(),
    m = parsed_date.getMonth()

  const firstDay = new Date(y, m, 1)
  const lastDay = new Date(y, m + 1, 0)

  const devices = store.getNodeDevicesRecursive(props.node)

  const kipo = await store.getTotalRange(firstDay, lastDay, 'KIPO', devices)
  const kipo_total = calculate_total_month(kipo).filter((e) => e.code === 'ECO')
  const kipo_values = kipo_total.map((v) => ({
    label: 'Kipopluie',
    value: v
  }))

  const solar = await store.getTotalRange(firstDay, lastDay, 'SOLAIRE', devices)
  const solar_total = calculate_total_month(solar).filter((e) => e.code === 'ECO')
  const solar_values = solar_total.map((v) => ({
    label: 'Soprasolar',
    value: v
  }))

  const aqua = await store.getTotalRange(firstDay, lastDay, 'HUUMBOX', devices)
  const aqua_total = calculate_total_month(aqua).filter((e) => e.code === 'ECO')
  const aqua_values = aqua_total.map((v) => ({
    label: 'Aquasmart',
    value: v
  }))

  dataset.value = kipo_values.concat(solar_values, aqua_values)
  fetching.value = false
}

ChartJS.register(ArcElement, Tooltip, CategoryScale, Legend)
onMounted(fetchMonth)
</script>

<style scoped>
.chart-savings {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chart-savings-loader {
  padding-top: var(--spacer);
}
</style>
