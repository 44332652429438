<template>
  <BandeauHome />
  <LayoutContainer class="home-view">
    <div class="home-view-global">
      <div class="section-savings">
        <CompositeCardSavings style="margin-bottom: var(--spacer)" />
        <CompositeCardInterventionsHome />
      </div>
      <div class="section-units">
        <CompositeCardUnitHome
          v-if="store.getRoot"
          v-for="node in store.getRoot.childrens"
          :node="node"
        />
      </div>
    </div>
  </LayoutContainer>
</template>

<script setup lang="ts">
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import BandeauHome from '@/components/common/CommonBandeauHome.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CompositeCardSavings from '@/components/composite/CompositeCardSavings.vue'
import CompositeCardUnitHome from '@/components/composite/CompositeCardUnitHome.vue'
import CompositeCardInterventionsHome from '@/components/composite/CompositeCardInterventionsHome.vue'

const store = useHypervisionStore()
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
select {
  padding: 20px;
}

.home-view {
  @include vlayout(var(--spacer-xl));
  padding-bottom: 80px;

  &-regroupements {
    h2 {
      margin-bottom: var(--spacer-1_5);
    }
  }
}

.home-view-global {
  display: flex;
  gap: var(--spacer-xl);
}

.section-savings {
  flex-grow: 1;
  min-width: 100px;
}

.section-units {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: var(--spacer);
}

.tablet,
.mobile {
  .home-view-global {
    flex-direction: column;
  }

  .section-units {
    min-width: 0;
  }
}

.tablet {
  .home-view-global {
    gap: var(--spacer-xl);
  }

  .section-savings-interventions {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacer-xl);
  }
}

.mobile {
  .home-view-global {
    gap: var(--spacer-xl);
  }

  .section-savings-interventions {
    grid-template-columns: 1fr;
    gap: var(--spacer);
  }
}
</style>
