<template>
  <LayoutDetail class="view-dashboard-data">
    <div class="view-dashboard-data-header">
      <h1>Mes données</h1>
    </div>
    <InputSelect
      border
      id="unit-selector"
      :options="getUnitOptions()"
      v-model="selected_unit"
      class="selector"
    />
    <CompositeSiteParameters
      v-if="store.getRoot?.childrens && selected_unit !== undefined"
      :node="store.getRoot?.childrens[selected_unit]"
    />
  </LayoutDetail>
</template>

<script setup lang="ts">
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CompositeSiteParameters from '@/components/composite/CompositeSiteParameters.vue'
import InputSelect from '@/components/common/input/InputSelect.vue'
import { onMounted, ref } from 'vue'
import type { SopremaInputSelectOption } from '@/components/common/input/InputSelect.vue'

const store = useHypervisionStore()

const selected_unit = ref(undefined)

function getUnitOptions(): SopremaInputSelectOption[] {
  if (!store.getRoot?.childrens) {
    return []
  }

  let options: SopremaInputSelectOption[] = store.getRoot.childrens.map((unit, index) => {
    return { label: unit.name, value: index.toString() }
  })

  return options
}
</script>

<style scoped>
.view-dashboard-data-header {
  margin-bottom: var(--spacer);
}

.selector {
  margin-bottom: var(--spacer);
}
</style>
