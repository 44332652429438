<template>
  <LayoutCard class="composite-card-unit">
    <div class="composite-card-unit-img-container">
      <img v-if="attributes?.get('UrlOUImage')" :src="attributes.get('UrlOUImage')" />
      <div v-else class="no-image">
        <Boxes :size="64" />
      </div>
    </div>
    <div class="composite-card-unit-body">
      <p class="uptitle composite-card-unit-body-country">{{ node.localization }}</p>
      <h3>{{ node.name }}</h3>
      <RouterLink :to="node_path(node)">
        <CommonButton class="btn-primary small composite-card-unit-cta">
          Voir le détail
          <ArrowRight :size="18" />
        </CommonButton>
      </RouterLink>
    </div>
    <CommonTag v-if="alerts > 0" classes="blue"> ALERTES ({{ alerts }}) </CommonTag>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '@/components/layout/LayoutCard.vue'
import CommonButton from '../common/CommonButton.vue'
import CommonTag from '../common/CommonTag.vue'
import { ArrowRight, Boxes } from 'lucide-vue-next'
import AttributeHelper from '@/service/service_attributes'
import { onMounted, ref } from 'vue'
import { node_path, type ApplicationTreeNode } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'

const store = useHypervisionStore()
const attributes = ref<AttributeHelper>()
const alerts = ref<number>(0)
const props = defineProps<{
  node: ApplicationTreeNode
}>()

function get_alerts(): number {
  const devices = store.getNodeDevicesRecursive(props.node)
  let output = 0
  devices.forEach((device) => {
    output += device.alerts.size
  })

  return output
}

onMounted(() => {
  attributes.value = store.getNodeAttributes(props.node)
  alerts.value = get_alerts()
})
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
@import '@/assets/animations.scss';

.composite-card-unit {
  @include hlayout(20px);

  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
  animation: moveIn cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &-img-container {
    height: 150px;
    border-radius: var(--br-medium);
    overflow: hidden;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &-body {
    @include vlayout(0);
    justify-content: center;
    flex: 1;

    &-country {
      letter-spacing: 2px;
      margin-bottom: 5px;
    }

    h3 {
      margin-bottom: var(--spacer);
    }
  }

  .common-tag {
    position: absolute;
    top: 40px;
    left: 0;
  }

  &-cta {
    min-width: 100%;
  }
}

.no-image {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--vc-primary);
  opacity: 0.6;
  background-color: var(--vc-gray2);
}

.mobile,
.tablet {
  .composite-card-unit {
    &-cta {
      min-width: 0;
    }
  }
}
</style>
