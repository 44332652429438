<template>
  <div class="composite-card-performance">
    <div class="composite-card-chart-delete">
      <Trash @click="remove" :size="20" />
    </div>
    <div class="composite-card-chart-dates">
      <div class="composite-card-chart-date">
        {{ new Date(chart.range_base[0]).toLocaleDateString('fr') }}
      </div>
      <div class="composite-card-chart-date">
        {{ new Date(chart.range_base[1]).toLocaleDateString('fr') }}
      </div>
    </div>
    <div class="composite-card-performance-header">
      <h3>{{ getChartName() }}</h3>
      <div class="composite-card-chart-selected">
        <p>
          <span
            >{{ chart.devices.length }} équipement(s){{
              chart.variables.length > 0 || chart.sensors.length > 0 ? ', ' : ''
            }}</span
          >
          <span v-if="chart.variables.length > 0"
            >{{ chart.variables.length }} variable(s){{
              chart.sensors.length > 0 ? ', ' : ''
            }}</span
          >
          <span v-if="chart.sensors.length > 0">{{ chart.sensors.length }} capteur(s)</span>
        </p>
      </div>
    </div>

    <div class="composite-card-chart-deviceinfos" v-if="chart.devices.length > 0">
      <p v-for="sensor in chart.sensors" :key="sensor">
        {{ store.getValue(sensor)?.name }}
      </p>
      <p v-for="variable in chart.variables" :key="variable">
        {{ store.getValue(variable)?.name }}
      </p>
    </div>
    <CommonButton style="margin-top: auto" class="btn btn-primary" @click="load">
      Voir le détail
      <ArrowRight :size="24" />
    </CommonButton>
  </div>
</template>

<script setup lang="ts">
import CommonButton from '../common/CommonButton.vue'
import { ArrowRight, Trash } from 'lucide-vue-next'
import type { components } from '@/types/gateway_schema'
import { useChartStore } from '@/stores/store_chart'
import { useRouter } from 'vue-router'
import { useGatewayStore } from '@/stores/store_gateway'

const store = useChartStore()
const gateway = useGatewayStore()
const router = useRouter()

const props = defineProps<{
  chart: components['schemas']['ChartDTO']
}>()

function load() {
  store.load(props.chart).then(() => {
    router.push('/dashboard/chart_builder')
  })
}

function remove() {
  if (confirm(`Souhaitez vous supprimer le graphique ?`)) {
    if (props.chart.id) {
      gateway.deleteChart(props.chart.id)
    }
  }
}

function getChartName() {
  if (props.chart.name) {
    return props.chart.name
  }

  return `Graphique n°${props.chart.id?.toString().padStart(5, '0')}`
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.composite-card-chart-deviceinfos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.composite-card-chart-date {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.5px;
  padding: 5px 10px;
  width: fit-content;
  background-color: black;
  color: white;
}

.composite-card-chart-dates {
  display: flex;
  gap: 5px;
}

.composite-card-chart-deviceinfos > p {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: var(--spacer-0_5) var(--spacer);
  background-color: var(--vc-gray2);
  border-radius: 100px;
}

.composite-card-chart-delete {
  cursor: pointer;
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 10px;
  border: 1px solid var(--vc-secondary);
  border-radius: 50%;
  right: 10px;
  padding: var(--spacer-0_5);
  width: 42px;
  height: 42px;

  &:hover {
    background-color: var(--vc-gray2);
  }
}

.composite-card-chart-selected {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  p,
  span {
    font-size: 12px;
    line-height: 18px;
  }
}

.composite-card-performance {
  @include vlayout(20px);
  align-items: center;
  position: relative;
  background-color: var(--vc-gray1);
  padding: 64px 32px 48px 32px;
  &-header {
    padding: 20px;
    max-width: fit-content;
    text-align: center;

    h3 {
      margin-bottom: 10px;
    }
  }

  &-body {
    text-align: center;

    .economy {
      margin-top: 10px;
      color: var(--vc-green);
    }
  }
}
</style>
