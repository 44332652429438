<template>
  <div class="chart-savings">
    <CommonColumnData
      class="chart-savings-title"
      style="margin-bottom: var(--spacer)"
      :label="name"
      :data="`${sum()} ${unit}`"
    />
    <CommonLoader v-if="dataset.length === 0" style="padding-top: 20px" />
    <div class="chart-container" v-else>
      <Doughnut
        :data="{
          labels: dataset.map((e) => e.title),
          datasets: [
            {
              backgroundColor: ['#8EB7FF', '#FEC62E', '#FC6944', '#9C5DF4'],
              data: dataset.map((e) => e.value)
            }
          ]
        }"
        :options="{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  return `${context.parsed.toFixed(0)} ${props.unit}`
                }
              }
            }
          }
        }"
        :style="{
          width: '150px'
        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonColumnData from '@/components/common/CommonColumnData.vue'
import CommonLoader from '@/components/common/CommonLoader.vue'
import type { DeviceValue } from '@/service/service_calculations'
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, Legend } from 'chart.js'
import { onMounted, ref } from 'vue'
import { Doughnut } from 'vue-chartjs'

const props = defineProps<{
  name: string
  dataset: DeviceValue[]
  unit: string
}>()

function sum() {
  let output = 0
  props.dataset.map((e) => {
    output += e.value ?? 0
  })

  return output.toFixed(0)
}

function getMonth() {
  const date = new Date()
  let appostroph = false
  const month = date.getMonth()
  if ([4, 7, 9].includes(month)) {
    appostroph = true
  }

  const month_name = date.toLocaleDateString('fr', {
    day: undefined,
    month: 'long',
    year: undefined
  })
  let result = month_name.charAt(0).toUpperCase() + month_name.slice(1)
  return `${appostroph ? "d'" : 'de '}${result}`
}

ChartJS.register(ArcElement, Tooltip, CategoryScale, Legend)
onMounted(() => {
  // const devices = store.getNodeDevicesRecursive(props.node)
  // dataset.value = devices.map((device) => ({
  //   label: device.name,
  //   value: device.values.find((value) => value.metric.includes('€'))
  // }))
})
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center;
}
.chart-savings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: var(--vc-white);
  padding: var(--spacer);
  border-radius: var(--br-medium);
}
</style>

<style>
.chart-savings-title > p {
  padding: 0 5px;
}
</style>
