<template>
  <LayoutDetail class="my-charts-view" :loading="fetching">
    <div class="view-charts-header">
      <h1>Mes graphiques</h1>
      <CommonButton class="btn-primary" @click="create"
        >Créer un nouveau graphique <Plus :size="20" />
      </CommonButton>
    </div>
    <LayoutColumns :columns="3" class="view-charts-list">
      <CompositeCardChart v-for="chart in store.user_charts" :chart="chart" />
    </LayoutColumns>
  </LayoutDetail>
</template>

<script setup lang="ts">
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import CommonButton from '@/components/common/CommonButton.vue'
import { useGatewayStore } from '@/stores/store_gateway'
import { onMounted, ref } from 'vue'
import CompositeCardChart from '@/components/composite/CompositeCardChart.vue'
import LayoutColumns from '@/components/layout/LayoutColumns.vue'
import { useChartStore } from '@/stores/store_chart'
import { useRouter } from 'vue-router'
import { Plus } from 'lucide-vue-next'

const store = useGatewayStore()
const chart_store = useChartStore()
const router = useRouter()
const fetching = ref(true)

onMounted(() => {
  store.fetchUserCharts().finally(() => {
    fetching.value = false
  })
})

function create() {
  chart_store.init()
  router.push('/dashboard/chart_builder')
}
</script>

<style scoped lang="scss">
.view-charts-header {
  margin-bottom: var(--spacer);
  display: flex;
  justify-content: space-between;
}

.mobile {
  .view-charts-header {
    flex-direction: column;
  }
}
</style>
